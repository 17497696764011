import Axios from '@/plugins/axios.js'
import Store from '@/plugins/store.js'
import Cookies from '@/plugins/cookies.js'
import Search from '@/mixins/search.js'
import Autocomplete from '@/mixins/autocomplete.js'
import MzHitListItem from '@/custom/HitListItem.vue'
import HitGridItem from '@/custom/HitGridItem.vue'
import MzContext from '@/custom/MzContext.js'
import Vue from 'vue'
import VueBus from 'vue-bus'
import VueCurrencyFilter from 'vue-currency-filter'
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import LineClamp from 'vue-line-clamp'
import ChDrawer from 'ch-drawer'
import VueLazyload from 'vue-lazyload'
import { mapState } from 'vuex'
import Translation from '@/plugins/translation.js'
import MzAutocomplete from './custom/MzAutocomplete.vue'
import VueObserveVisibility from 'vue-observe-visibility'

// import styles for ui-components
import '@mezereon/ui-components-vue/dist/ui-components-vue.css'
import '@/style/index.scss'

import '@/plugins/QueryState.js'
import '@/plugins/filters.js'

// import custom content
import '@/custom/style.scss'

import {
  MzActionBar,
  MzHitsInfo,
  MzBanner,
  MzList,
  MzTree,
  MzHitsGrid,
  MzHitsList,
  MzPagination,
  MzSlider,
  MzRefinements,
  MzSearchBox,
  MzSelections
} from '@mezereon/ui-components-vue'

import Recommendations from './Recommendations.vue'

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})
Vue.use(VueScrollTo)
Vue.use(VueBus)
Vue.use(Axios)
Vue.use(Store)
Vue.use(Cookies)
Vue.use(Translation)
Vue.use(PortalVue)
Vue.use(LineClamp)
Vue.config.productionTip = false
Vue.use(ChDrawer, { zIndex: 99999 })
Vue.use(VueLazyload)
Vue.use(VueObserveVisibility)

if (document.getElementById('mz-autocomplete')) {
  window.mz.suggest = new Vue({
    components: {
      MzAutocomplete,
      MzSearchBox
    },
    mixins: [Autocomplete]
  }).$mount('#mz-autocomplete')
}

if (document.getElementById('mz-app')) {
  window.mz.app = new Vue({
    components: {
      'mz-search-box': MzSearchBox,
      MzHitListItem,
      HitGridItem,
      MzContext,
      MzActionBar,
      MzHitsInfo,
      MzBanner,
      MzList,
      MzTree,
      MzHitsGrid,
      MzHitsList,
      MzPagination,
      MzSlider,
      MzRefinements,
      MzSelections
    },
    mixins: [Search],
    data() {
      return {
        portalNav: false
      }
    },
    computed: {
      filterLayoutClass() {
        return `mz-filter-layout-${this.filterLayout}`
      },
      ...mapState('search', ['filterLayout'])
    },
    methods: {
      togglePortalNav(show) {
        this.portalNav = show
      }
    }
  }).$mount('#mz-app')
}

// create mz-recs dynamically
if (!document.getElementById('mz-recs')) {
  const mzRecs = document.createElement('div')
  mzRecs.id = 'mz-recs'
  document.body.insertBefore(mzRecs, document.body.firstChild)
}

if (document.getElementById('mz-recs')) {
  window.mz.recs = new Vue({
    render: (h) => h(Recommendations)
  }).$mount('#mz-recs')
}

document.addEventListener('DOMContentLoaded', function () {
  // find all add-to-cart links and add click tracking
  const linkList = document.querySelectorAll('a.button')
  Array.from(linkList).forEach(function (link) {
    if (link.pathname !== '/cart.php') {
      return
    }
    link.addEventListener('click', function () {
      const match = link.search.match(/product_id=([0-9]*)/i)
      if (match) {
        window.mz.track('add2cart', { uid: match[1] })
      }
    })
  })
})

window.mz.enabled = true

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],ref:"autocomplete",class:['mz-autocomplete', _vm.config.layout === 'reverse' ? 'reverse' : ''],on:{"keydown":_vm.keyHandler}},[_c('div',{ref:"arrow",staticClass:"mz-arrow-wrapper"},[(_vm.autocomplete && _vm.isLoaded)?_c('div',{staticClass:"mz-arrow"}):_vm._e()]),(_vm.autocomplete && _vm.isLoaded)?_c('div',{staticClass:"mz-autocomplete-container"},[_c('div',{staticClass:"mz-body"},[_c('div',{staticClass:"mz-left"},[_vm._l((_vm.config.sideAreaSections),function(section,index){return [(
              section.visible === true &&
              section.modal === 'modal-popular' &&
              _vm.suggestResult &&
              _vm.suggestResult._ &&
              _vm.suggestResult._.items &&
              _vm.suggestResult._.items.length > 0
            )?_c('b',{key:`label-${index}`},[(_vm.query() !== '')?[_vm._v(" "+_vm._s(section.config.label)+" ")]:_vm._e(),(_vm.query() === '' && _vm.config.popularOnFocus)?[_vm._v(" "+_vm._s(section.config.labelPopular)+" ")]:_vm._e()],2):_vm._e(),(
              section.visible === true &&
              section.modal === 'modal-popular' &&
              _vm.suggestResult &&
              _vm.suggestResult._ &&
              _vm.suggestResult._.items &&
              _vm.suggestResult._.items.length > 0
            )?_c('div',{key:index,staticClass:"mz-suggest-list"},_vm._l((_vm.suggestResult._.items),function(item){return _c('div',{key:item.text,class:{
                'mz-suggest-item': true,
                'mz-active': _vm.currentSuggestion === item.text
              },attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.clickSearch(item.text)},"focus":function($event){return _vm.setCurrentSuggestion(item.text, true)},"mouseenter":function($event){$event.preventDefault();return _vm.setCurrentSuggestion(item.text)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e(),(
              section.visible === true &&
              section.modal === 'modal-side-custom'
            )?_c('div',{key:index,staticClass:"mz-suggest-list",domProps:{"innerHTML":_vm._s(section.config.html)}}):_vm._e(),(
              section.visible === true && section.modal === 'modal-facets'
            )?_c('div',{key:index,staticClass:"mz-suggest-list"},_vm._l((section.config.facets),function(group,idx){return _c('div',{key:idx},[(
                  _vm.suggestResult &&
                  _vm.suggestResult[group.facet.name] &&
                  _vm.suggestResult[group.facet.name].items
                )?_c('b',[_vm._v(" "+_vm._s(group.label)+" ")]):_vm._e(),(
                  _vm.suggestResult &&
                  _vm.suggestResult[group.facet.name] &&
                  _vm.suggestResult[group.facet.name].items &&
                  _vm.suggestResult[group.facet.name].items.length > 0
                )?_c('div',{key:index,staticClass:"mz-suggest-list"},_vm._l((_vm.suggestResult[group.facet.name].items),function(item){return _c('div',{key:item.original,staticClass:"mz-suggest-item",attrs:{"tabindex":"0"},domProps:{"innerHTML":_vm._s(_vm.$options.filters['value'](item.original))},on:{"click":function($event){$event.preventDefault();return _vm.clickFacet(group.facet, item.original)}}})}),0):_vm._e()])}),0):_vm._e(),(
              section.visible === true &&
              section.modal === 'modal-recent' &&
              _vm.suggestRecent &&
              _vm.suggestRecent.length > 0
            )?_c('b',{key:`recent-${index}`},[_vm._v(" "+_vm._s(section.config.label)+" ")]):_vm._e(),(
              section.visible === true &&
              section.modal === 'modal-recent' &&
              _vm.suggestRecent &&
              _vm.suggestRecent.length > 0
            )?_c('div',{key:index,staticClass:"mz-suggest-list"},_vm._l((_vm.suggestRecent),function(item){return _c('div',{key:item.text,class:{
                'mz-suggest-item': true
              },attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.clickRecent(item.text)}}},[_vm._v(" "+_vm._s(item.text)+" "),_c('a',{staticClass:"mz-suggest-delete",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteSearch(item.text)}}},[_vm._v(" del ")])])}),0):_vm._e()]})],2),_c('div',{staticClass:"mz-right"},[_vm._l((_vm.config.mainAreaSections),function(section,index){return [(
              section.visible === true && section.modal === 'modal-top-items'
            )?[(_vm.toggleView)?_c('a',{key:`list-${index}`,class:{
                'mz-btn': true,
                'mz-btn-sm': true,
                'mz-active': _vm.view === 'list'
              },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeView('list')}}},[_c('fa',{attrs:{"icon":_vm.faBars,"fw":""}})],1):_vm._e(),(_vm.toggleView)?_c('a',{key:`grid-${index}`,class:{
                'mz-btn': true,
                'mz-btn-sm': true,
                'mz-active': _vm.view === 'grid'
              },staticStyle:{"margin-right":"5px"},attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeView('grid')}}},[_c('fa',{attrs:{"icon":_vm.faThLarge,"fw":""}})],1):_vm._e(),(_vm.suggestItems.total)?_c('b',{key:`text-${index}`},[(_vm.query() !== '')?[_vm._v(" "+_vm._s(section.config.label)+" "),_c('kbd',[_vm._v(" "+_vm._s(_vm.currentSuggestion || _vm.complete || _vm.query() || 'empty')+" ")])]:_vm._e(),(_vm.query() === '' && _vm.config.popularOnFocus)?[_vm._v(" "+_vm._s(section.config.labelPopular)+" ")]:_vm._e()],2):_vm._e(),(_vm.suggestItems.total)?_c('span',{key:`summary-${index}`,staticClass:"mz-summary-count mz-hidden-xs",staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(_vm.$t('SummaryCount', { total: _vm.suggestItems.total, took: _vm.suggestItems.took }))+" ")]):_vm._e(),(_vm.isListView)?_c('div',{key:index,staticClass:"mz-row"},[_c('div',{staticClass:"mz-autocomplete-list"},[_vm._l((_vm.suggestItems.items),function(hit,idx){return [_vm._t("list-item",null,{"hit":hit,"index":idx,"primaryKey":_vm.primaryKey,"config":_vm.config,"isHidden":_vm.isHidden,"onItemClick":_vm.onItemClick})]})],2)]):_vm._e(),(_vm.isGridView)?_c('div',{key:index,staticClass:"mz-row"},[_c('div',{staticClass:"mz-autocomplete-grid"},[_vm._l((_vm.suggestItems.items),function(hit,idx){return [_vm._t("grid-item",null,{"hit":hit,"index":idx,"primaryKey":_vm.primaryKey,"config":_vm.config,"isHidden":_vm.isHidden,"onItemClick":_vm.onItemClick})]})],2)]):_vm._e()]:_vm._e(),(
              section.visible === true &&
              section.modal === 'modal-main-custom'
            )?_c('div',{key:index,staticClass:"mz-row",domProps:{"innerHTML":_vm._s(section.config.html)}}):_vm._e()]})],2)]),_c('div',{staticClass:"mz-footer"},[_c('a',{attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.$t('View all {total} results', { total: _vm.suggestItems.total }))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.doEnter.apply(null, arguments)}}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }